import {
  REDIRECT_FROM_LIVE_REPORTING,
  REDIRECT_FROM_PERFORMANCE,
} from '../constants'

export const getRedirects = async () => [
  {
    from: '/',
    to: '/home',
  },
  {
    from: '/settings/item-availability',
    to: '/menu/item-availability',
  },
  {
    from: '/settings/menu',
    to: '/menu/edit',
  },
  {
    from: '/live-reporting',
    to: '/home',
    isEnabled: REDIRECT_FROM_LIVE_REPORTING,
  },
  {
    from: '/performance',
    to: '/analytics/performance-score',
    isEnabled: REDIRECT_FROM_PERFORMANCE,
  },
  {
    from: '/marketing',
    to: '/marketing/offers',
  },
  {
    from: '/help',
    to: '/help/support-requests',
  },
  {
    from: '/menu',
    to: '/menu/item-availability',
  },
  {
    from: '/orders-and-invoices',
    to: '/orders-and-invoices/order-history',
  },
  {
    from: '/settings',
    to: '/settings/restaurant-details/edit-business-details',
  },
]
