export class RoutesConfigManager {
  #routes
  #redirects

  constructor(routes, redirects) {
    this.#routes = routes
    this.#redirects = redirects
  }

  #getRoutes(routes) {
    if (!Array.isArray(routes) || !routes.length) return

    return routes.reduce((result, route) => {
      if (route.hasOwnProperty('isEnabled') && !route.isEnabled) return result

      const routes = this.#getRoutes(route.routes)
      if (routes) route.routes = routes

      delete route.isEnabled
      result.push(route)

      return result
    }, [])
  }

  #getRedirects(redirects) {
    return redirects.reduce((result, redirect) => {
      if (!redirect.hasOwnProperty('isEnabled') || redirect.isEnabled) {
        result[redirect.from] = redirect.to
      }

      return result
    }, {})
  }

  getRoutesConfig() {
    return {
      routes: this.#getRoutes(this.#routes),
      redirects: this.#getRedirects(this.#redirects),
    }
  }
}
